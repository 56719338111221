import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'



class Whatis extends React.Component {


  render() {  

    return (
      <section className="whatis pt-3 pt-md-5">
        <div className="container">
          <div className="shadoww">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <h2 className="heading-h2">Crypto Token Development Company - <span className='bluecolor'>Coinsclone</span></h2>
              </div>
            </div>
            <div className="text-center">
              <p className="pharagraph text-center">Coinsclone is a premier Crypto token development company that offers reliable and secure token development services on various blockchains. By launching a Crypto token with Coinsclone, you can get a 100% assured premium token with some extraordinary features. Our team of experts, designs and creates tokens in the best way possible to ensure your Crypto token development purpose is completely satisfied. 
              </p>
              <p className="pharagraph text-center mb-2 mb-md-5">Additionally, At Coinsclone, we provide top-notch token development services on extensive blockchains like Ethereum, Binance Smart Chain, TRON, Solana, Polygon, etc. From ideation to token launch and marketing, We have real-world experience in delivering comprehensive offerings on token development to our clients across industry hosts.
              </p>
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/crypto-token/leading-crypto-token-overview.png"
                  alt="Leading Crypto Token Development Company Overview"
                  width={1100}
                />
                </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Whatis